import React from "react";

const Loader = () => {
  return (
    <div className="border w-screen h-screen flex items-center justify-center -300">
      <div
        className="inline-block h-24  w-24 text-center animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      ></div>
    </div>
  );
};

export default Loader;
